import react, { useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [result, setResult] = useState("");

  const onSubmit = data => {
    setResult("Sending...");

    axios.post('/api/contact', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res=>{
      setResult("Message sent. Thank you!");
      console.log(res);
      console.log(res.data);
      window.location = "/" //This line of code will redirect you once the submission is succeed
    })
    .catch(err=>{
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
        setResult("Message failed. There appears to be a problem with the service handler.");
      } else if (err.request) {
        // The request was made but no response was received
        // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request);
        setResult("Message failed. There was a problem with the request.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message);
        setResult("Message failed. A problem occurred.");
      }
      console.log(err.config);
    });
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
      >

        <div className="row">

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="contact-form form-control theme-light"
                placeholder="First Name"
                {...register("firstname", { required: true })}
              />
              {errors.firstname && errors.firstname.type === "required" && (
                <span className="invalid-feedback">First name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="contact-form form-control theme-light"
                placeholder="Last Name"
                {...register("lastname", { required: true })}
              />
              {errors.lastname && errors.lastname.type === "required" && (
                <span className="invalid-feedback">Last name is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                type="email"
                className="contact-form form-control theme-light"
                placeholder="Email address"
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <input
                type="text"
                className="contact-form form-control theme-light"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">Subject is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="contact-form form-control theme-light"
                placeholder="Message: `Tell me more about your services!`"
                {...register("message", { required: true })}
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">

            <div className="form-group mb-3">
              <div className="form-control theme-light">
                <input
                  type="checkbox"
                  placeholder="Privacy Policy"
                  {...register("privacy", { required: true })}
                />  I agree to the <a href="/privacy.html">Privacy Policy</a>
                {errors.privacy && (
                  <span className="invalid-feedback">Accepting the privacy policy is required.</span>
                )}
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="form-control theme-light">
                <input
                  type="checkbox"
                  placeholder="Terms of Service"
                  {...register("terms", { required: true })}
                />  I agree to the <a href="/terms.html">Terms of Service</a>
                {errors.terms && (
                  <span className="invalid-feedback">Accepting the terms of service is required.</span>
                )}
              </div>
            </div>

            <div className="form-group mb-3">
              <div className="form-control theme-light">
                <input
                  type="checkbox"
                  {...register("newsletter", {})}
                />  sign me up for your newsletter
              </div>
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <div className="btn-bar">
                <button className="px-btn px-btn-white">Send Message</button>
              </div>
              {result && (
                <span className="feedback">{result}</span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

        </div>
      </form>
    </>
  );
};

export default ContactForm;
