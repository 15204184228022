import React from "react";
import {
  //FaFacebookF,
  //FaTwitter,
  //FaInstagram,
  FaLinkedinIn,
  //FaPinterestP,
} from "react-icons/fa";

const SocialShare = [
  //{ Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  //{ Social: <FaTwitter />, link: "https://www.linkedin.com/" },
  //{ Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaLinkedinIn />, link: "https://linkedin.com/in/bobbyl" },
  //{ Social: <FaPinterestP />, link: "https://www.pinterest.com/" },
];

const PolicyLinks = [
  { name: "Privacy Policy", link: "/privacy" },
  { name: "Terms of Service", link: "/terms" },
  { name: "Cookie Policy", link: "/cookies" },
  { name: "Disclaimer", link: "/disclaimer" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            {SocialShare.map((val, i) => (
              <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank">
                {val.Social}
              </a>
            ))}
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          <div className="footer-links mb-2">
            {PolicyLinks.map((val, i) => (
              <a key={i} href={`${val.link}`} className="me-3">
                {val.name}
              </a>
            ))}
            {/* <a href="#" class="termly-display-preferences">Consent Preferences</a> */}
          </div>
          <p>
            © {new Date().getFullYear()} copyright{" "}
            <a
              href="https://mindhive.tech"
              target="_blank"
              rel="noreferrer"
            >
              MindHive LLC
            </a>{" "}
            all right reserved
          </p>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;